/**
 * Enum representing the types of toast notifications.
 * - Success = 'success'
 * - Error = 'error'
 * - Warning = 'warning'
 * - Info = 'info'
 */
export enum ToastTypeEnum {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
}
