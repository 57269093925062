import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_w5bu2sx7by3sz6f3kfihanbzuu/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.7_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom@1_w5bu2sx7by3sz6f3kfihanbzuu/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/core/intercomeChat/IntercomChat.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/customComponents/buttons/Button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/customComponents/progress/ProgressLine.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/customComponents/texts/Text.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/customComponents/toast/Toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/icons/3dots.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/add.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/alert.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/andercore-a.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/andercore-logo-big.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/andercore-logo-small.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/billing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/business-handshake.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/calendar.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/cancel.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/category-inverter.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/chat-bubble-oval-smiley.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/check-circle.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/check.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/chevron-up.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/billing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/claimManagement.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/lastUpdated.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/messageRead.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/noComments.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/quality.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/billing-information.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/late-delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/material-defect.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/other.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/packaging-damage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/paid-already.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/payment-amount.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/performance.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/product-quantity.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/production-date.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/serial-number.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/software.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/wrong-products.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/reasons/wrong-quantity.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/claimManagement/resolved.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/clock.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/coffee-mug.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/coins.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/collapse.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/country/de.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/country/en.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/country/es.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/country/fr.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/country/it.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/delivery-truck.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/dispute-bubble.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/document.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/download.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/edit-pencil.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/billing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/claim.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/notfound.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/orders.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/quotes.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/track-n-trace-search.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/empty-state/track-n-trace.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/envelope.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/expand.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/eye-closed.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/eye-open.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/file-attachement.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/home-big.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/home.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/invoice-due.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/loader.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/location.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/logout.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/mail.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/minus.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/office-building.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/orders.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/percent.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/printer.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/profile.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/quote-new.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/quotes.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/settings.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/shipping-box.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/shop.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/smartphone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/acPowerRange.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/batteries.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/batteryVoltage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/cables.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/cableType.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/capacity.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/cellType.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/colour.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/container.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/filter-no-results.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/filter.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/inverterPower.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/inverters.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/inverterType.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/length.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/maxDCVoltage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/modulePower.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/modules.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/moduleType.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/numberOfPhases.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/outputPower.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/pallet.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/panelEfficiency.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/search.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/storage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/store/warranty.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/supportContact.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/timer.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/customer-support.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/interface-calendar.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/location-office.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/phone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/delivered.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/in-manufacturing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/order-placed.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/transfer-cart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/track-and-trace/progress-bar/truck.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/travel-map-location-pin.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/upload.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/wallet.svg");
;
import(/* webpackMode: "eager" */ "/app/src/icons/whats-app.svg");
