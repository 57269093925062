import { ToastTypeEnum } from '~/types-and-enums/toastTypes';
import { IconName } from '~/components/core/Icon';
import { colors } from '~/utils/tailwindUtils';

/**
 * Get the related classes for the toast based on the type
 * This is very specific to the design of the toast, so it's better to keep it here
 * @param type
 * @returns
 */
export const getToastRelatedClassesByToastType = (type: ToastTypeEnum) => {
  switch (type) {
    case ToastTypeEnum.Success:
      return {
        iconName: IconName.Resolved,
        iconColor: colors.green[800],
        containerClassName: 'bg-green-200 border-green-400',
        headerClassName: 'text-green-800 font-semibold',
        subHeaderClassName: 'text-green-800 font-medium',
      };
    default:
      return {
        iconName: IconName.Alert,
        iconColor: colors.gray[800],
        containerClassName: 'bg-gray-200 border-gray-400',
        headerClassName: 'text-gray-800 font-semibold',
        subHeaderClassName: 'text-gray-800 font-medium',
      };
  }
};
