'use client';

import { useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { ToastTypeEnum } from '~/types-and-enums/toastTypes';
import { Icon, IconName } from '~/components/core/Icon';
import { updateSearchParams } from '~/utils/clientUtils';
import { spacing } from '~/utils/tailwindUtils';
import Card from '../cards/Card';
import Flex from '../flex/Flex';
import Text from '../texts/Text';
import { getToastRelatedClassesByToastType } from './toastUtil';

type ToastProps = {
  headerText: string;
  urlSearchParamName: string;
  subHeaderText?: string;
  type: ToastTypeEnum;
};

/**
 * A toast component that will be displayed on the top right of the screen either for 5 seconds or until the user clicks on close
 * @param headerText - The text to be displayed as the header of the toast
 * @param urlSearchParamName - The name of the search param that will be used to show/hide the toast
 * @param subHeaderText - The text to be displayed as the subheader of the toast
 * @param type - The type of the toast (Success, Error, Warning, Info)
 * @returns
 */
const Toast = ({
  headerText,
  urlSearchParamName,
  subHeaderText,
  type,
}: ToastProps) => {
  const searchParams = useSearchParams();
  const [isOpen, setIsOpen] = useState(
    Boolean(searchParams?.get(urlSearchParamName))
  );

  const toastRef = useRef<HTMLDivElement>(null);

  // Close the toast and remove the search param
  const closeToast = useCallback(() => {
    const params = new URLSearchParams(searchParams);
    setIsOpen(false);
    params.delete(urlSearchParamName);
    updateSearchParams(params);
  }, [searchParams, urlSearchParamName]);

  // Close the modal if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        toastRef.current &&
        !toastRef.current.contains(event.target as Node)
      ) {
        closeToast();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
      const timer = setTimeout(() => {
        closeToast();
      }, 5000); // Close the toast after 6 seconds

      return () => {
        clearTimeout(timer);
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, closeToast]);

  if (!isOpen) return null;

  const {
    iconName,
    iconColor,
    containerClassName,
    headerClassName,
    subHeaderClassName,
  } = getToastRelatedClassesByToastType(type);

  return (
    <Card
      className={twMerge(
        'rounded-lg fixed top-16 left-4 right-4 md:right-16 md:left-auto w-auto md:w-fit z-50 transition-opacity',
        isOpen
          ? 'opacity-100 ease-in duration-300'
          : 'opacity-0 ease-out duration-300',
        containerClassName
      )}
      ref={toastRef}
      data-testid="toast"
    >
      <Flex className="flex-col gap-4">
        <button
          className="absolute top-3 right-3 text-gray-600"
          onClick={closeToast}
        >
          <Icon name={IconName.Cancel} size={spacing[5]} colorMode="fill" />
        </button>
        <Flex>
          <Icon name={iconName} color={iconColor} className="mr-2" />
          <Flex className="flex-col justify-center gap-1 mr-6">
            {headerText && (
              <Text className={headerClassName}>{headerText}</Text>
            )}
            {subHeaderText && (
              <Text className={subHeaderClassName}>{subHeaderText}</Text>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

export default Toast;
